.wrapper {
  height: var(--height-48);
  border: 5px solid transparent;
  border-radius: 50%;
  width: var(--height-48);
  border-left: 5px solid var(--circular-loader-wrapper-border-color);
  animation: RotateLeft 1s infinite;
}

.insideCircular {
  position: relative;
  top: -5px;
  left: -5px;
  width: var(--height-48);
  height: var(--height-48);
  border-radius: 50%;
  border: 5px solid transparent;
  border-right: 5px solid var(--circular-loader-inside-circular-border-color);
  animation: RotateRight 1s infinite;
}

@keyframes RotateLeft {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes RotateRight {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-720deg);
  }
}
