.stateButton {
  border: 2px solid var(--border-color-gray);
  background: var(--header-left-section-background-color);
  padding: 7px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.drawerStateDrawerButton,
.drawerStateTournamentButton.hideRightSide {
  transform: rotate(0deg);
}

.drawerStateTournamentButton,
.hideLeftSide {
  transform: rotate(180deg);
}


.drawerTogglerSvg path {
  stroke: var(--header-left-section-toggler-svg-path-stroke);
}

@media (min-width: 768px) {
  .drawerStateDrawerButton {
    position: absolute;
    left: calc(var(--indent-base) * -1);
  }

  .drawerStateTournamentButton {
    position: absolute;
    right: -10px;
  }
}