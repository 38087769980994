.gameFrameWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(
    --mobile-launch-game-pop-up-game-frame-background-color
  );
  z-index: calc(var(--layout-lvl-1) + 4);
}

.gameFrame {
  height: calc(100% - var(--height-48));
  width: 100%;
}
