.wrapperTicketTitle {
  display: flex;
  background-color: rgb(var(--dark-gray));
  align-items: center;
  justify-content: space-between;
  color: rgb(var(--white));
  font-size: 12px;
  transition: 0.5s;


  height:var(--ticket-header-height)
}

.isHidden {
  height: var(--height-48);
}

.leftSection {
  display: flex;
  align-items: center;
}

.leftSection>div {
  margin-left: calc(var(--indent-base) / 2);
}

.leftSection>div:first-child {
  margin-left: 0;
}

.ticketType {
  color: rgb(var(--semi-gray))
}

.ticketTime {
  color: rgb(var(--semi-gray));
}