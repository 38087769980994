.wrapper {
  height: calc(var(--app-height) - var(--height-48) - var(--navigation-bar-height));
  width: 100%;
  padding:var(--indent-base);
  background-color: rgb(var(--dark));
}

.bannerSkelet {
  height: 250px;
  width: 100%;
  border-radius: var(--border-l);
  overflow: hidden;

  margin-bottom: var(--indent-base);
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--indent-base);
}

.tournamentLoader {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: var(--border-l);
}

.eventLoader {
  width: 100%;
  overflow: hidden;
  height: 90px;
  border-radius: var(--border-l);
  margin-bottom: var(--indent-base);
}