.wrapperDrawerCategoryInfo {
  --calculate-height: 105px;
  --short-size-width: 60px;

  position: absolute;
  height: calc(100% - var(--calculate-height));
  background: var(--category-info-by-sport-background-color);
  top: calc(var(--calculate-height) + var(--indent-base));
  width: calc(
    100% -
      (var(--short-size-width) + var(--indent-base-double) + var(--indent-base))
  );
  z-index: var(--layout-lvl-1);
  border: 2px solid var(--border-color-gray);
  border-radius: var(--border-s);
  animation: ShowCategories 0.5s forwards;
  overflow-y: auto;
  right: -100%;
}

.disabledScroll {
  overflow: hidden;
}

.menuItem {
  height: var(--height-48);
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--border-color-gray);
  padding: 0 var(--indent-base);
  transition: 0.5s;
}

.menuItem:last-child {
  border-bottom: none;
}

.icon {
  width: 25px;
  height: 25px;
  border: 2px solid var(--border-color-gray);
  border-radius: 50%;
}

.categoryName {
  margin-left: var(--indent-base);
  color: var(--category-info-by-sport-category-name-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrapperTableHeader {
  display: flex;
  align-items: center;
  font-size: var(--font-size-m);
  color: var(--category-info-by-sport-table-header-color);
  position: sticky;
  top: 0;
  width: 100%;
  background: var(--back-header-sport-background-color);
}

.wrapperTableHeader .categoryName {
  color: var(--category-info-by-sport-table-header-category-name-color);
  margin-left: 0;
}

.hideSportInfo {
  overflow: hidden;
  width: 100px;
  animation: MoveToLeft 0.5s forwards;
  padding: 0;
}

@keyframes ShowCategories {
  100% {
    right: var(--indent-base);
  }
}
@keyframes MoveToLeft {
  100% {
    width: 0;
    transform: translateX(-100%);
  }
}
