.wrapperExpress {
  padding: var(--indent-base);
  border-top: 2px solid var(--border-color-gray);
  color: var(--express-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -18px 42px -1px rgb(var(--dark));
}

.title {
  font-size: 0.8rem;
  margin-bottom: calc(var(--indent-base) / 2);
}

.betCount {
  font-size: 0.7rem;
  color: var(--express-bet-count);
}

.opacity {
  opacity: 0.5;
}