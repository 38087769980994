.wrapperHeader {
  height: var(--height-48);
  background: var(--header-background-color);
  padding: 0 var(--indent-base);
  justify-content: space-between;
  border-bottom: var(--header-bottom-border);

  position: sticky;
  top: 0;
  z-index: calc(var(--layout-lvl-1) + 4);
}

.jivoBtn {
  width: 36px;
  height: 36px;
  border-radius: var(--border-s);
  background: var(--header-jivo-button-background-color);
  margin-left: var(--indent-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--header-jivo-border)
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.marginLeft {
  margin-left: 20px;
}

.smlMargin {
  margin-left: var(--indent-base);
}

.btnText {
  color: var(--header-button-text-color);
}

.arrowBack {
  width: 36px;
  height: 36px;
  padding: 10px;
  transform: rotate(-90deg);
}

.arrowBack path {
  fill: var(--header-arrow-back-path-fill);
}

.loaderWrapper {
  width: 120px;
  height: 30px;
  border-radius: var(--border-s);
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 1170px) {
  .wrapperHeader {
    margin-bottom: var(--indent-base);
    border-radius: var(--border-l);
  }
}

@media (min-width: 1170px) {
  .wrapperHeader {
    height: calc(var(--height-48) * 2 );
    position: relative;
    padding: 0 50px;
    border-radius: var(--border-24);
    background: var(--header-desktop-background-color);
    border: 2px solid var(--border-color-gray);
    margin-bottom: var(--indent-base);
  }
}
