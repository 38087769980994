.wrapperBetSlipContent {
  background: var(--bet-slip-content-background-color);
  border-top: 2px solid var(--border-color-gray);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--indent-base);
  /* 55px button place bets  */
  max-height: calc(100% - 55px);
}

.betsContent {
  overflow-y: auto;
  height: 100%;
}

.borderBottom {
  border-bottom: 2px solid var(--border-color-gray);
}

.borderBottom:last-child {
  border-bottom: 0;
}

.placeBetWrapper {
  margin: 0 var(--indent-base);
}

.leftSide {
  padding-left: calc(var(--indent-base) * 2);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.oddWrapper {
  width: var(--oddWidth);
  height: var(--oddHeight);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-s);
  font-size: 13px;
  color: var(--bet-slip-content-odd-wrapper-color);
  transition: 0.5s;
  font-weight: 700;
}
