.itemStyles {
  font-size: 12px;
  color: var(--casino-provider-selector-item-styles-color);
  height: 32px;
}

.mainItem {
  font-size: 12px;
  color: var(--casino-provider-selector-main-item-color);
}
