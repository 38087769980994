.wrapperPopUpHeader {
  display: flex;
  justify-content: space-between;
  height: var(--height-48);
  padding: var(--indent-base);
  background-color: var(--pop-up-header-background-color);
  border-bottom: 2px solid var(--border-color-gray);
  align-items: center;
  width: 100%;
}

.title {
  font-weight: 400;
  font-size: 14px;
  color: var(--pop-up-header-title-color);
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70%;
}

.closeButton {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 2px solid var(--border-color-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pop-up-header-close-button-background-color);
}

.closeSvg {
  width: 8px;
  height: 8px;
}

.closeSvg path,
.arrowBackSvg path {
  fill: var(--pop-up-header-svg-path-fill);
}
