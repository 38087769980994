.wrapperCalendarModal {
  border-top: 2px solid var(--border-color-gray);
  background-color: var(--calendar-modal-wrapper-background-color);
  border-radius: var(--border-xl);
  padding: var(--indent-base);
  padding-bottom: calc(var(--indent-base) * 2);
}

.calendarMB {
  margin-bottom: calc(var(--indent-base) * 2);
  width: 100%;
  border-radius: var(--border-s);
  background: var(--calendar-modal-background-color);
  border: 2px solid var(--border-color-gray);
  padding: var(--indent-base);
  color: var(--calendar-modal-color);
}
