.home-slider .carousel.carousel-slider {
  overflow: initial;
  color: var(--banner-title-color);
}

.home-slider .carousel .control-dots {
  bottom: calc(var(--indent-base) * -3)
}

.home-slider .carousel .control-dots .dot.selected {
  background: rgb(var(--primary));
}