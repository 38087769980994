.wrapperProviderSearch {
  padding: var(--indent-base) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 2px solid var(--border-color-gray);
  width: 100%;
}

.searchInput {
  margin-left: 10px;
  display: flex;
  align-items: center;
  border: none;
}

.searchIconInput {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

.searchIconInput path {
  fill: var(--provider-search-icon-input-path-fill);
}

.closeIcon {
  width: 26px;
  height: 26px;
  padding: 6px;
}

.closeIcon path {
  fill: var(--provider-search-close-icon-path-fill);
}
