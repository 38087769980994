

.wrapperPlayer {
  margin-bottom: calc(var(--indent-base) / 2);
}

.playerContent {
  padding: var(--indent-base);
  margin-bottom: var(--indent-base-double);
  max-height: 70vh;
  overflow-y: auto;
  width: 100%;
}

.paginationWrapper {
  padding: 0 var(--indent-base) var(--indent-base);
}

@media (min-width: 768px) {
  .wrapperPlayers {
    height: 550px;
    width: 450px;
  }
}
