.recommendedTitle {
  display: flex;
  margin-bottom: var(--indent-base);
}

.title {
  color: var(--recommended-games-game-title-color);
  font-weight: 400;
  margin-left: var(--indent-base);
}

.color {
  color: var(--recommended-games-game-title-color);
}


.editWidthSize {
  width: calc(100% - var(--indent-base));
}

.recommendedGamesSvg path {
  fill: var(--recommended-games-svg-path-fill);
}


.rowCasinoGamesWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  overflow-x: auto;
  padding-bottom: var(--indent-base);
  padding-right: var(--indent-base);
  min-height: 130px;
}

.marginB {
  margin-bottom: var(--indent-base);
}