.wrapperTournamentPlayer {
  border-radius: var(--border-xl);
  border: 2px solid var(--border-color-gray);
  padding: 5px var(--indent-base);
  background-color: var(--tournament-player-background-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapperPlayerInfoAndPosition {
  display: flex;
  align-items: center;
}

.playerPosition {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--border-color-gray);
  border-radius: 50%;
  font-size: 10px;
  background-color: var(--tournament-player-position-background-color);
  color: var(--tournament-player-position-color);
}

.avatarWrapper {
  width: 42px;
  height: 42px;
  border: 2px solid var(--border-color-gray);
  padding: 2px;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  background-color: var(--tournament-player-avatar-background-color);
  border-radius: 50%;
}

.wrapperPlayerInfo {
  margin-left: 8px;
}

.playerName {
  color: var(--tournament-player-info-name-color);
  font-size: 14px;
  margin-bottom: 5px;
}

.sum {
  color: var(--tournament-player-info-sum-color);
}

.resultValue {
  font-size: 14px;
  font-weight: 400;
}

.playerResultSum {
  margin-bottom: 5px;
  color: var(--tournament-player-result-sum-color);
}

.wrapperCoins {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--tournament-player-wrapper-coins-color);
}

.coin {
  margin-left: 5px;
}

.first {
  border-color: var(--tournament-player-position-first-border-color);
  background: var(--tournament-player-position-first-background-color);
  color: var(--tournament-player-position-first-color);
}

.second {
  border-color: var(--tournament-player-position-second-border-color);
  background: var(--tournament-player-position-second-background-color);
  color: var(--tournament-player-position-second-color);
}

.third {
  border-color: var(--tournament-player-position-third-border-color);
  background: var(--tournament-player-position-third-background-color);
  color: var(--tournament-player-position-third-color);
}
