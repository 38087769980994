.bonuses {
  display: flex;
  flex-direction: column;
  gap: var(--indent-base);
}

.progressWrapper {
  background: var(--vip-bonuses-progress-background-color);
  width: var(--full);
  border-radius: var(--border-l);
  padding: var(--indent-base);
  margin-bottom: var(--indent-base);
}

.progressColor {
  color: var(--vip-bonuses-progress-title-color);
}

.opacity {
  color: var(--vip-bonuses-progress-title-opacity-color);
  opacity: 0.5;
}

.progress {
  margin-bottom: var(--indent-base);
}

.downText {
  color: var(--vip-bonuses-progress-down-text-color);
  opacity: 0.5;
}

.desktopRow {
  background: var(--vip-bonuses-progress-background-color);
  color: var(--vip-bonuses-progress-title-color);
  margin-bottom: var(--indent-base);
  font-size: var(--font-size-m);
  padding:var(--indent-base);
  border-radius: var(--border-l);
}
