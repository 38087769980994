.itemSportWrapper {
  margin-bottom: var(--indent-base);
  height: var(--height-48);
}

.shortView {
  width: var(--height-48);
  padding: 0;
  opacity: 0.7;
}

.shortView > div {
  width: 100%;
  justify-content: center;
}

.shortView > div > span {
  display: none;
}

.activeSport {
  border: 2px solid var(--list-sports-active-sport-border-color);
  opacity: 1;
}

.activeSport svg path {
  fill: var(--list-sports-active-sport-svg-path-fill);
}
