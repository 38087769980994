.wrapperDefaultGame {
  background-color: var(--default-game-image-background-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: var(--indent-base);
}

.img {
  position: absolute;
  right: 0;
  top: 0;
}

.gameName {
  font-size: 14px;
  font-size: 500;
  color: var(--default-game-image-game-name-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
