.wrapperProviderTournaments {
  width: 100%;
  overflow-y: auto;
  /* Provider tournament height */
  /* height: calc(var(--app-height) - 338px) */
  max-height: calc(var(--app-height) - 30%);
}

.dash {
  margin-bottom: var(--indent-base);
  width: 100%;
  border-bottom: 2px solid var(--border-color-gray);
}

.sliderInfoWrapper {
  color: var(--provider-tournaments-slider-info-wrapper-color);
  position: absolute;
  bottom: var(--indent-base);
  right: 10px;
  font-weight: 700;
  font-size: 16px;
  background-color: var(
    --provider-tournaments-slider-info-wrapper-background-color
  );
  padding: calc(var(--indent-base) / 2) var(--indent-base);
  border-radius: var(--border-s);
}
