.wrapperContentCategories {
  position: sticky;
  top: var(--height-48);
  z-index: calc(var(--layout-lvl-1) + 3);
  background-color: var(--casino-categories-content-background-color);
  padding-top: var(--indent-base);
}

.wrapperCasinoCategories {
  min-height: 35px;
  padding-bottom: var(--indent-base);
  padding-right: var(--indent-base);
}

.scroll {
  display: flex;
  overflow-x: auto;
  width: 100%;
}

.arrowButton {
  border: 2px solid var(--casino-categories-arrow-button-border-color);
  height: 38px;
  width: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  right: calc(var(--indent-base) * -1);
  z-index: 1;
  background: var(--casino-categories-arrow-button-background-color);
}

.arrowButton:first-child {
  right: initial;
}

.left {
  transform: rotate(-90deg);
}

.right {
  transform: rotate(90deg);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.wrapperScrollArrow {
  position: relative;
}

.favoriteButton {
  padding: 0 5px;
}

.fillStar path {
  fill: var(--casino-categories-fill-star-path-fill);
}

.haveFavGame path {
  fill: var(--casino-categories-have-fav-game-path-fill);
}

.categoryIcon {
  margin-bottom: calc(var(--indent-base) / 2);
  width: 45px;
  height: 20px;
}


@media (min-width: 768px) and (max-width: 1170px) {
  .wrapperContentCategories {
    margin-bottom: var(--indent-base);
    background-color: transparent;
  }
}

@media (min-width: 1170px) {
  .wrapperContentCategories {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
  }

  .wrapperScrollArrow {
    /* 320 search + 20 margin */
    max-width: calc(100% - 340px);
  }

  .wrapperScrollArrow.fullWidth {
    width: 100%;
    max-width: calc(100% - var(--indent-base));
  }

  .wrapperCasinoCategories {
    margin-bottom: 20px;
    overflow-x: hidden;
    position: relative;
    padding: 0 calc(var(--indent-base) * 4);
  }

  .searchWrapper {
    width: 320px;
    margin-left: 20px;
    flex-shrink: 0;
  }
}
