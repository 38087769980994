.animationFixedUp {
  animation: FixedUp 1s forwards;
}

@keyframes FixedUp {
  0% {
    top: 0;
  }

  100% {
    top: -100%;
  }
}

/* FADE  */
.animationFadeOut {
  animation: FadeOut 1s forwards;
}


@keyframes FadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animationFadeOutModal {
  animation: FadeOutModal 1s forwards;
}


@keyframes FadeOutModal {
  0% {
    z-index: 9;
    opacity: 1;
  }

  100% {
    z-index: -1;
    opacity: 0;
  }
}

/* FADE  */
.animationFadeIn {
  animation: FadeIn 1s forwards;
}


@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



.modalOpenMobileAnimation {
  animation: MoveToUp 0.5s forwards;
}

@keyframes MoveToUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.modalCloseMobilePopUpAnimation {
  animation: MoveToDownPopUP 0.5s forwards;
}

@keyframes MoveToDownPopUP {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}

.modalOpenDesktopAnimation {
  animation: MoveToUpDesktop 0.5s forwards;
}

@keyframes MoveToUpDesktop {
  0% {
    height: 0;
    transform: translateY(0);
  }

  100% {
    height: 50%;
    transform: translateY(-50%);
  }
}

.modalCloseDesktopAnimation {
  animation: MoveToBottomDesktop 0.5s forwards;
}

@keyframes MoveToBottomDesktop {
  0% {
    height: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }

  100% {
    height: 0;
    opacity: 0;
    transform: translateY(50%);
  }
}

.modalCloseMobileAnimation {
  animation: MoveToBottom 0.7s forwards;
}

@keyframes MoveToBottom {
  0% {
    bottom: 0;
  }

  100% {
    bottom: -100%;
  }
}



/* DRAWER */
.animationOpenDrawer {
  animation: MoveToRight 0.7s forwards;
  animation-delay: 0.5s;
}

@keyframes MoveToRight {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.animationCloseDrawer {
  animation: MoveToLeft 0.3s forwards;
}

@keyframes MoveToLeft {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animationSelectorGrowing {
  animation: SelectorGrowing 0.3s forwards;
}

@keyframes SelectorGrowing {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 150px;
  }
}

.animationSelectorReducing {
  animation: animationSelectorReducing 0.3s forwards;
}

@keyframes animationSelectorReducing {
  0% {
    max-height: 150px;
  }

  100% {
    max-height: 0;
  }
}

.animationCasinoDrawerReducing {
  animation: animationCasinoDrawerReducing 0.3s forwards;
}

@keyframes animationCasinoDrawerReducing {
  0% {
    width: 320px;
  }

  100% {
    width: 100px;
  }
}

.animationCasinoDrawerGrowing {
  animation: animationCasinoDrawerGrowing 0.3s forwards;
}

@keyframes animationCasinoDrawerGrowing {
  0% {
    width: 100px;
  }

  100% {
    width: 320px;
  }
}

.animationRightSideGrowing {
  animation: animationRightSideGrowing 0.3s forwards;
}

@keyframes animationRightSideGrowing {
  0% {
    width: 0px;
  }

  100% {
    width: 380px;
  }
}

.animationRightSideClosing {
  animation: animationRightSideClosing 0.3s forwards;
  overflow: hidden;
}

@keyframes animationRightSideClosing {
  0% {
    width: 380px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

/* absolute  FOR DEPOSIT TABS*/
.animationLeft {
  animation: animationLeft 0.5s forwards;
}

@keyframes animationLeft {
  0% {
    left: 50%;
  }

  100% {
    left: 0;
  }
}

.animationRight {
  animation: animationRight 0.5s forwards;
}

@keyframes animationRight {
  0% {
    left: 0;
  }

  100% {
    left: 50%;
  }
}


/* payments content  */

.animationWithdraw {
  animation: ShowWithdraw 0.5s forwards;
}

@keyframes ShowWithdraw {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animationDeposit {
  animation: ShowDeposit 0.5s forwards;
}

@keyframes ShowDeposit {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}


/* BET SLIP  */

.animationBetSlipShow {
  animation: ShowBetSlip 0.5s forwards;
}

@keyframes ShowBetSlip {
  0% {
    opacity: 0;
    bottom: -100%;
  }

  100% {
    opacity: 1;
    bottom: 60px;
  }
}

.animationBetSlipHide {
  animation: HideBetSlip 1s forwards;
}

@keyframes HideBetSlip {
  0% {
    opacity: 1;
    bottom: 60px;
  }

  100% {
    opacity: 1;
    bottom: -60px;
  }
}

.animationMoveToUpBtn {
  animation: animationMoveToUpBtn 1s forwards;
}

@keyframes animationMoveToUpBtn {
  0% {
    opacity: 0;
    bottom: -80px;
  }

  100% {
    opacity: 1;
    bottom: 80px;
  }
}

.animationMoveToDownBtn {
  animation: animationMoveToDownBtn 1s forwards;
}

@keyframes animationMoveToDownBtn {
  0% {
    opacity: 1;
    bottom: 80px;
  }

  100% {
    opacity: 0;
    bottom: -80px;
  }
}

.animationShowCasinoGame {
  animation: animationShowCasinoGame 1s forwards;
}

@keyframes animationShowCasinoGame {
  0% {
    right: -100%;
  }

  100% {
    right: 0;
  }
}


.animationMoveToLeftNotification {
  animation: animationMoveToLeftNotification 1s forwards;
}

@keyframes animationMoveToLeftNotification {
  0% {
    left: 0;
  }

  100% {
    left: -260px;
  }
}



.animationMoveToRightNotification {
  animation: animationMoveToRightNotification 1s forwards;
}

@keyframes animationMoveToRightNotification {
  0% {
    left: -260;
  }

  100% {
    left: 0px;
  }
}


.vipClubFilledStars {
  animation: vipClubFilledStars 1.5s forwards;
}

@keyframes vipClubFilledStars {
  0% {
    top: -80px;
  }

  100% {
    top: 0
  }
}

.vipClubLights {
  animation: vipClubLights 2s forwards;
}

@keyframes vipClubLights {
  0% {
    top: 0;
  }

  100% {
    top: -112%
  }
}


.fadeInInfinity {
  animation: fadeInInfinity 2s infinite linear;
}

@keyframes fadeInInfinity {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}




/* TURKISH BANK PopUp */
.showPopUpBankList {
  animation: ShowPopUpBankList 0.5s forwards;
}

@keyframes ShowPopUpBankList {
  0% {

    transform: translateY(100%);
  }

  100% {

    transform: translateY(0%);
  }
}

.hidePopUpBankList {
  animation: HidePopUpBankList 0.5s forwards;
}

@keyframes HidePopUpBankList {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}