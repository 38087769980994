.wrapperTimer {
  display: flex;
  margin-bottom: var(--indent-base);
  justify-content: center;
  color: var(--timer-wrapper-color);
}

.time {
  color: var(--timer-time-color);
  margin-left: var(--indent-base);
}

.time:first-child {
  margin-left: 0;
}
