.wrapperProviderList {
  padding: 0 var(--indent-base) var(--indent-base);
  margin-top: var(--indent-base);
  max-height: 350px;
  overflow-y: auto;
  margin-right: var(--indent-base);
  width: 100%;
}

.wrapperProviderList::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.wrapperProviderList::-webkit-scrollbar-track {
  background: var(--provider-list-scrollbar-track-background-color);
}

.wrapperProviderList::-webkit-scrollbar-thumb {
  background: var(--provider-list-scrollbar-thumb-background-color);
}

.wrapperProviderList::-webkit-scrollbar-thumb:hover {
  background: var(--provider-list-scrollbar-thumb-hover-background-color);
}
