.wrapperDrawer {
  height: 100dvh;
  display: flex;
}

.content {
  width: 90%;
  height: 100%;
  border-radius: 0 var(--border-xl) var(--border-xl) 0;
}

.closeArea {
  width: 10%;
  height: 100%;
  animation: FadeIn 0.5s forwards;
  animation-delay: 1s;
  opacity: 0;
}

@keyframes FadeIn {
  100%{
    opacity: 1;
  }
}