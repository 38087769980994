.marginButton {
  margin-bottom: calc(var(--indent-base) + 5px);
}

.active {
  background-color: var(--casino-provider-buttons-active-button-background-color);
}

.providersSvg path {
  fill: var(--casino-provider-svg-path-fill);
}
