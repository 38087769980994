.wrapperBetSlipTabs {
  display: flex;
  align-items: center;
}

.tab {
  font-size: 12px;
  margin-left: 5px;
  color:rgb(var(--gray))
}

.activeTab {
  border:2px solid var(--border-color-gray);
  padding: 7px var(--indent-base);
  border-radius: var(--border-xl);
  background: rgb(var(--dark-gray));
  color:rgb(var(--white))
}

.closeIcon {
  margin-left: var(--indent-base);
  padding:2px;
}