.wrapperProgressWagering {
  height: 10px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: 2px solid var(--border-color-gray);
  background-color: var(--progress-wagering-wrapper-background-color);
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--progress-wagering-progress-background-color);
  height: 100%;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--progress-wagering-title-row-color);
}

.maxValue {
  padding: 5px;
  border-radius: var(--border-xs);
}

.wagerText {
  margin-bottom: 4px;
}

.row {
  margin-bottom: 6px;
}
