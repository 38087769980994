.oddIndicate {
  position: absolute;
  right: 2px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.greenAnimation {
  top: 2px;
  background-color: var(--indicator-green-animation-background-color);
  animation: Blinks 500ms 3 forwards;
}

.redAnimation {
  bottom: 2px;
  background-color: var(--indicator-red-animation-background-color);
  animation: Blinks 500ms 3 forwards;
}

@keyframes Blinks {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
