.wrapperProfileTabs {
  padding-left: var(--indent-base);
  overflow-x: auto;
  display: flex;
  padding-bottom: var(--indent-base);
}

.navigationButton {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  color: var(--profile-tabs-navigation-button-color);
  text-decoration: none;

  font-size: 12px;
  font-weight: 400;

  margin-left: 32px;
}

.navigationButton:first-child {
  margin-left: 0;
}

.activeNav {
  transition: 0.5s;
  border-bottom: 2px solid var(--profile-tabs-active-nav-border-color);
}

.activeNav .text {
  padding-bottom: 8px;
}

.text {
  padding-bottom: 10px;
  white-space: nowrap;
}
