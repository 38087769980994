.wrapperButtons {
  display: flex;
}

.buttonBackground {
  background-color: var(--game-actions-close-button-background-color);
  border-radius: 50%;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--border-color-gray);
  margin-left: 8px;
  padding: 5px;
}

.fill path {
  fill: var(--game-actions-close-button-svg-path-fill);
}
