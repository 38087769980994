.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.wrapperTitle {
  padding: var(--indent-base);
  margin-bottom: var(--indent-base);
}

.content .wrapperTabs {
  width: calc(var(--full) - var(--indent-base-double));
  margin-bottom: var(--indent-base);
  border-bottom: 2px solid transparent;
}

.marginInput {
  margin-bottom: var(--indent-base);
  flex-shrink: 0;
}

.wrapperForms {
  width: 100%;
  display: flex;
}

.content .passwordField,
.wrapperForm > div,
.lostPsw {
  width: calc(var(--full) - var(--indent-base-double));
}

.wrapperForm {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
}

.formOne {
  transform: translateX(0%);
  transition: 0.5s;
}
.formTwo {
  transition: 0.5s;
  transform: translateX(-100%);
}
.formThree {
  transition: 0.5s;
  transform: translateX(-200%);
}

.lostPsw {
  text-align: right;
  color: var(--sign-in-modal-lost-password-color);
  font-weight: 400;
  font-size: 12px;
}

.dash {
  width: calc(var(--full) - var(--indent-base-double));
  margin-bottom: var(--indent-base);
  border-bottom: 2px solid var(--border-color-gray);
}

.content .btn {
  width: calc(var(--full) - var(--indent-base-double));
  max-width: calc(var(--full) - var(--indent-base-double));
  min-width: calc(var(--full) - var(--indent-base-double));
  margin-bottom: var(--indent-base);
}

.btn:last-child {
  margin-bottom: 0;
}

.reg {
  opacity: 0.7;
}

@media (min-width: 768px) {
  .wrapperSignInModal {
    width: 500px;
  }
}
