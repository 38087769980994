.providerRowsWrapper {
  display: grid;
  grid-auto-columns: 120px;
  grid-auto-flow: column;
  grid-gap: 6px;
  overflow-x: auto;
  padding-bottom: 10px;
}


.wrapperProviderRows {
  margin-bottom: var(--indent-base);
}