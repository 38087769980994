.wrapperReferralCopyContent {
  color: rgb(var(--white));
}

.marginB {
  margin-bottom: var(--indent-base-double);
}

.bg path {
  fill: var(--button-color-primary-text);
}
