.wrapperLoader {
  width: 100%;
  height: var(--button-height-xxl);
  position: relative;
  overflow: hidden;
  border-radius: var(--border-l);
  /* margin-bottom: var(--indent-base); */
}


.cashOutInfo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(var(--third));
  font-size: 16px;
}