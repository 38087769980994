.wrapperPaymentFrame {
  position: fixed;
  width: 96%;
  height: 96%;
  left: 2%;
  bottom: -100%;
  animation: ShowPaymentFrame 1s forwards;
  z-index: var(--layout-lvl-4);
  background-color: var(--payment-frame-background-color);
  border: 2px solid var(--border-color-gray);

  display: flex;
  justify-content: center;
  align-items: center;
}

.frame {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: var(--border-xl);
}

.closeBtn {
  position: absolute;
  background-color: var(--payment-frame-close-button-background-color);
  top: calc(calc(var(--indent-base)) * -1);
  right: calc(calc(var(--indent-base)) * -1);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: calc(var(--indent-base) / 2);
  border: 2px solid var(--border-color-gray);
  z-index: 5;
}

@keyframes ShowPaymentFrame {
  100% {
    bottom: 2%;
  }
}
