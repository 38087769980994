.sliderButton {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--border-color-gray);
  border-radius: 50%;
  background-color: var(--bonus-slider-arrow-slider-button-background-color);
}

.rotateArrow {
  transform: rotate(180deg);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.sliderArrowSvg path {
  fill: var(--bonus-slider-arrow-slider-button-svg-path-fill);
}
