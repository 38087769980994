.inputWrapper {
  border-radius: var(--border-s);
  border: 2px solid var(--border-color-gray);
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 2px 10px;
  position: relative;
  background: rgb(var(--dark));
  background: var(--input-base-autofill-background-color)
}

.borderColorfulGreen,
.borderColorfulGreen label {
  color: rgb(var(--primary));
  border-color: rgb(var(--primary));
}

.borderColorfulRed,
.borderColorfulRed label {
  border-color: rgb(var(--red));
  color: rgb(var(--red));
}

.overflow {
  overflow: hidden;
}

.inputWrapperSize-full {
  width: var(--full);
}

.inputWrapperSize-small {
  width: var(--small);
}

.inputWrapperSize-medium {
  width: var(--medium);
}

.inputWrapperSize-large {
  width: var(--large);
}

.label {
  position: absolute;
  left: 10px;
  color: var(--input-base-autofill-text-color);
  opacity: 0.5;
  z-index: 1;
  font-weight: bold;
  transition: 0.5s;
}

.labelRight {
  left: 25px;
}

.input {
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: 2px;
  background-color: transparent;
  color: var(--input-base-autofill-text-color);
  font-weight: bold;
}

.input:placeholder-shown ~ .label {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 11px;
}

.input:placeholder-shown ~ .labelRight {
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  font-size: 11px;
}

.input:not(placeholder-shown) ~ .label {
  top: -15px;
  transform: translateY(0%);
  z-index: 1;
  left: 0px;
  font-size: 10px;
}

.input::placeholder {
  font-size: 0;
}

.input:focus ~ .label {
  top: -15px;
  transform: translateY(0%);
  left: 0px;
  z-index: 2;
  font-size: 10px;
}

.disabled {
  pointer-events: none;
  text-overflow: ellipsis;
}

.errorBorder {
  border-color: rgb(var(--red));
}

.errorText {
  position: absolute;
  bottom: -15px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  left: 0;
  font-size: 11px;
  overflow: hidden;
  color: rgb(var(--red));
}

.rowChildrenData {
  display: flex;
  align-items: center;
}

.rowChildrenData svg path {
  fill: var(--input-base-row-children-data-svg-path-fill);
}

.input:focus ~ .label.hideLabel {
  opacity: 0;
}

.input:not(placeholder-shown) ~ label.hideLabel {
  opacity: 0;
}

.input:placeholder-shown ~ label.hideLabel {
  opacity: 1;
}

.fistIcon {
  padding-left: 0;
}

.inputWrapper .label.labelUp {
  top: -15px;
  transform: translateY(0%);
  left: 0px;
  z-index: 2;
  font-size: 10px;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 40px var(--input-base-autofill-background-color) inset;
  -webkit-text-fill-color: var(--input-base-autofill-text-color);
  -webkit-background-clip: text;
  background-clip: text;
}

.input:-moz-autofill {
  box-shadow: 0 40px var(--input-base-autofill-background-color) inset;
  color: var(--input-base-autofill-text-color);
  background-clip: text;
}

.input:-ms-autofill {
  box-shadow: 0 40px var(--input-base-autofill-background-color) inset;
  color: var(--input-base-autofill-text-color);
  background-clip: text;
}
