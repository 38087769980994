.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--indent-base-half);
  background-color: var(--promocode-row-background);
  border: 2px solid rgb(var(--border-color-gray));
  padding: var(--indent-xs) var(--indent-m);
  border-radius: var(--border-xxl);
}

.name {
  color: var(--promo-code-rule-color);
  font-size: var(--font-size-s);
}

.value {
  color: var(--promocode-row-value-color);
  font-size: var(--font-size-s);
  font-weight: 700;
}
