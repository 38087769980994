.input {
  border-radius: var(--border-s);
  border: 2px solid var(--border-color-gray);
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 2px 10px;
  position: relative;
  background: var(--input-base-autofill-background-color);
  margin-bottom: var(--indent-base);
  color: white;
}

.input input {
  background: inherit;
  color: var(--input-base-autofill-text-color);
}

.input #PhoneInputCountry {
  margin-right: var(--indent-base);
}

.wrapper {
  position: relative;
}

.errorBorder {
  border-color: rgb(var(--red));
}

.error {
  position: absolute;
  top: 40px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  left: 0;
  font-size: 11px;
  overflow: hidden;
  color: rgb(var(--red));
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 40px var(--input-base-autofill-background-color) inset;
  -webkit-text-fill-color: var(--input-base-autofill-text-color);
  -webkit-background-clip: text;
  background-clip: text;
}

.input:-moz-autofill {
  box-shadow: 0 40px var(--input-base-autofill-background-color) inset;
  color: var(--input-base-autofill-text-color);
  background-clip: text;
}

.input:-ms-autofill {
  box-shadow: 0 40px var(--input-base-autofill-background-color) inset;
  color: var(--input-base-autofill-text-color);
  background-clip: text;
}
