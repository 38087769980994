.content {
  width: var(--full);
  height: var(--full);
  overflow: auto;
}

.games {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 8px;
  margin-bottom: var(--indent-base);
  padding: 0 var(--indent-base);
  overflow-y: auto;
}
