.wrapperContent {
  min-height: var(--app-height);
  display: flex;
  width: 100%;
  position: relative;
}

.wrapperContent:after {
  content: '';
  position: fixed;
  background: var(--desktop-layout-content-after-background-color);
  max-height: 700px;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  z-index: 0;
}

.desktopContent {
  width: 100%;
  padding: var(--indent-base);
  position: relative;
  z-index: 1;
  /* TODO becase of animation right side  */
  overflow: hidden;
}

.desktopBody {
  border-radius: var(--border-24);
  border: 2px solid var(--border-color-gray);
  margin-left: 0;
  padding: 30px;
  background: var(--desktop-layout-desktop-body-background-color);
}
