.wrapperCasinoGridGames {
  margin-bottom: calc(var(--indent-base) * 2);
}

.notFoundBlock {
  text-align: center;
}

.shareSvg {
  margin-left: var(--indent-base);
}