.wrapperButtonLanguage {
  width: 20px;
  height: 20px;
  transition: 0.5s;
  cursor: pointer;
}


.wrapperButtonLanguage svg {
  width:100%;
  height: 100%;
}