.wrapperTabs {
  display: flex;
  position: relative;
  border-radius: var(--border-xl);
  border: 2px solid rgba(var(--border-color-gray), 1);
  margin-bottom: var(--indent-base);
}

.buttonModifier {
  height: 40px;
  width: 100%;
  flex-shrink: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-l);
  color: var(--withdraw-tabs-button-modifier-color);
}

.index {
  position: relative;
  z-index: 2;
}

span.index {
  margin-left: 10px;
}

.activeText {
  color: var(--withdraw-tabs-active-text-color);
}

.activeText path {
  fill: var(--withdraw-tabs-active-text-path-fill);
}

.activeTab {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  border-radius: var(--border-xl);
  border: 2px solid var(--withdraw-tabs-active-tab-border-color);
  background-color: var(--withdraw-tabs-active-tab-background-color);
  transition: 0.5s;
}
