.bonusWrapper {
  border: 2px solid var(--border-color-gray);
  width: calc(100% - 16px);
  border-radius: var(--border-xl);
  padding: var(--indent-base);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0 8px;
  flex-shrink: 0;

  background: var(--bonus-content-background-color);
}

.bonusName {
  color: var(--bonus-content-bonus-name-color);
  margin-bottom: 5px;
}

.bonusMinDep {
  color: var(--bonus-content-bonus-min-deposit-color);
}

.bonusOffer {
  color: var(--bonus-content-bonus-offer-color);
  font-weight: 700;
  font-size: 25px;
}
