.wrapperPlaceBet {
  padding: 5px var(--indent-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: var(--border-s);
  /* border: 2px solid var(--button-color-primary-border); */
  
  margin-bottom: var(--indent-base);

  height: calc(var(--height-48) + var(--indent-base));
  transition: 0.5s;
  flex-shrink: 0;
  position: relative;

}


.readyToBet {
  background: var(--button-color-primary);
  /* border: 2px solid var(--button-color-primary-border); */
}

.mainText {
  margin-bottom: var(--indent-base-half);
}

.subInfo {
  font-size: 12px;
  color: var(--button-color-primary-text);
}

.disabled {
  opacity: 0.5;
  background-color: rgb(var(--dark));
  color: rgb(var(--white));
}

.disabled .subInfo {
  color: rgb(var(--white));
  border: 2px solid var(--border-color-gray);
}

.loader {
  /* width: calc(100% - calc(var(--indent-base) * 2 )); */
  width: 100%;
  border-radius: var(--border-s);
  height: 100%;
  overflow: hidden;
  position: absolute;
  opacity: 0.7;
}

.error {
  background-color: rgb(var(--red));
}