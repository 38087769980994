.wrapperAppNotify {
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--app-notify-border-color);
  background: var(--app-notify-background-color);
  padding: var(--indent-base);

  position: fixed;
  bottom: calc(var(--height-48) + var(--indent-base-double));
  border-radius: var(--border-l);
  width: 95%;
  left: 2.5%;
  z-index: 3;
  box-shadow: var(--app-notify-box-shadow-color) 0px 3px 8px;
}

.leftContent {
  display: flex;
  align-items: center;
}

.appText {
  color: var(--app-notify-text-color);
  font-size: 11px;
  margin-left: 8px;
}

.title {
  color: var(--app-notify-title-color);
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 3px;
}

.subTitle {
  color: var(--app-notify-subtitle-color);
  font-size: 10px;
  font-weight: 400;
}

.wrapperAppNotify .logoWrapper {
  width: 32px;
  height: 32px;
  border: 1px solid var(--app-notify-logo-border-color);
  border-radius: var(--border-s);
  padding: 1px;
  background: var(--app-notify-logo-background-color);
}

.rightSection {
  display: flex;
  align-items: center;
}

.closePopUp {
  margin-left: var(--indent-base);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid var(--border-color-gray);
  padding: 5px;
}

.closePopUp path {
  fill: var(--app-notify-close-button-svg-path-fill)
}
  
.hide {
  bottom: calc((var(--height-48) + var(--indent-base)) * -2);
}
