.wrapperNotification {
  position: relative;
  left: -250px;
  width: 250px;
  min-height: 45px;
  z-index: var(--layout-lvl-4);
  background-color: var(--notification-wrapper-background-color);
  border-radius: var(--border-l);
  border: 2px solid var(--border-color-gray);
  display: flex;
  align-items: center;
  padding: var(--indent-base);
  justify-content: space-between;
  margin-bottom: var(--indent-base);
}

.row {
  display: flex;
  align-items: center;
}

.row svg {
  flex-shrink: 0;
}

.text {
  margin-left: var(--indent-base);
  color: var(--notification-text-color);
  font-size: 12px;
}

.closeIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid var(--border-color-gray);
  background: var(--notification-close-icon-background-color);
  padding: 5px;
  flex-shrink: 0;
}

.closeIcon path {
  fill: var(--notification-close-icon-path-fill);
}
