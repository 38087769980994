.wrapperTurkishMefete {
  display: flex;
  align-items: center;
}

.checkboxDescriptions {
  font-size: 12px;
  color: var(--turkish-mefete-checkbox-descriptions-color);
  margin-left: var(--indent-base);
}
