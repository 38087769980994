.wrapperSystem {
  padding: var(--indent-base);
  border-top: 2px solid var(--border-color-gray);
  color: var(--system-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opacity {
  opacity: 0.5;
}