.wrapperBonusPack {
  border: 2px solid var(--border-color-gray);
  border-radius: var(--border-l);
  margin-bottom: var(--indent-base);
  padding: var(--indent-base);
}

.bonusWrapper {
  margin-bottom: var(--indent-base);
}

@media (min-width: 1024px) {
  .desktopWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .desktopWrapper > div {
    width: initial;
  }
}
