.wrapperBonusSection {
  margin-bottom: calc(var(--indent-base) * 2);
}

.wrapperAvailableBonuses {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: calc(var(--indent-base) * 2);
}

.hideBonuses {
  opacity: 0;
}

.bonusWrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
}

.visibleWrapper {
  width: 100%;
  display: flex;
  transition: 0.5s;
}

.wrapperCheckbox {
  display: flex;
  align-items: center;
}

.bonusCheckboxText {
  font-size: 14px;
  color: var(--available-bonuses-bonus-checkbox-text-color);
  margin-left: var(--indent-base);
}