.checkbox {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 40px;
  height: 20px;
  background-color: var(--checkbox-background-color);
  border: 1px solid var(--border-color-gray);
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 rgb(var(--soft-gray));
  transition: 0.5s;
}

.checkbox:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  background: transparent;
  width: 15px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

.checkbox:checked {
  /* TODO MOVE THIS COLOR  */
  box-shadow: inset 20px 0 0 0 rgb(var(--checkbox-active-bg));
  border-color: var(--checkbox-checked-border-color);
  background-color: var(--checkbox-checked-background-color);
}

.checkbox:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}
