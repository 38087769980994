.wrapperModal {
  position: fixed;
  bottom: 0%;
  width: var(--full);
  left: 0;
  z-index: calc(var(--layout-lvl-3) + 1);
}

.black {
  top: 0;
  position: fixed;
  z-index: var(--layout-lvl-3);
  background-color: var(--modal-black-background-color);
  left: 0;
  width: var(--full);
  height: var(--full);
}

.wallet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--full);
  height: var(--full);
}

@media (min-width: 768px) {
  .wrapperModal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
