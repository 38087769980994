.wrapperTournamentsByCategoryId {
  position: absolute;
  height: calc(100% - var(--height-48));
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--category-tournament-list-background-color);
  overflow-y: auto;
  border-radius: var(--border-l) var(--border-l) var(--border-s)
    var(--border-s);

  animation: ShowTournamentByCategory 0.5s forwards;
}

.wrapperTournament {
  height: var(--height-48);
  display: flex;
  align-items: center;
  padding: var(--indent-base);
  font-size: 14px;
  color: var(--category-tournament-wrapper-tournament-color);
  border-bottom: 2px solid var(--border-color-gray);
}

.wrapperTournament:last-child {
  border-bottom: 2px solid var(--border-color-gray);
}

.icon {
  width: 25px;
  height: 25px;
  border: 2px solid var(--border-color-gray);
  border-radius: 50%;
}

.tournamentName {
  margin-left: var(--indent-base);
}

@keyframes ShowTournamentByCategory {
  100% {
    top: var(--height-48);
  }
}
