.wrapperOddAnimation {
  animation: Rotate 0.5s forwards;
}

@keyframes Rotate {
  0% {
    transform:  scale(0.5);
  }

  100% {
    transform:  scale(1);
  }
}