.marginB {
  margin-bottom: var(--indent-base);
}

.title {
  font-size: var(--font-size-l);
  color: var(--general-bonuses-title-color);
  margin-bottom: var(--indent-base);
}

.availableWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--indent-base);
}

@media (min-width: 768px) {
  .availableWrapper > div,
  .activeBonuses > div {
    width: var(--full);
    max-width: var(--full);
  }
}
