.wrapperMoneyOperations {
  /* TODO android issues */
  height: calc(95vh -  var(--height-48)); 
  width: 100%; 
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--indent-base);
  transition: 0s;
}

.walletForms {
  /* TODO: remove it  */
  background: var(--money-operations-wallet-forms-background-color);
  width: 100%;
  max-height: 100%;
  border-radius: var(--border-s);
  overflow: hidden;
  min-height: 85%;
  transition: 0.5s;
}

.headerWrapper {
  background: var(--money-operations-wallet-forms-header-background);
}

.content {
  /* 28 header + 12 margin  */
  height: calc(100% - 60px);
  border-top: 2px solid var(--border-color-gray);
  background-color: var(--money-operations-wallet-forms-content-background-color);
  width: 100%;
  padding:var(--indent-base);
  /* Because of phone input label */
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 var(--border-s) var(--border-s);
  height: 90%;
}

.formWrapper{
  height: 100%;
  overflow: hidden;
  opacity: 0;
  animation-delay: 0.5s;
  position: relative;
  padding-top: var(--indent-base-double)
}

.submitButton {
  flex-shrink: 0;
}

.loader {
  margin:0 auto;
}

@media (min-width: 768px) {
  .wrapperMoneyOperations{
    height: 550px;
    width:450px;
  }
}