.inputTooltip {
  display: flex;
  align-items: center;
}

.grayColor {
  margin-left: var(--indent-base);
}

.grayColor path {
  fill: var(--tooltip-svg-gray-color);
}

.tooltipWrapper {
  position: absolute;
  bottom: var(--height-48);
  right: var(--indent-base);
  background-color: rgb(var(--semi-dark));
  padding: var(--indent-base);
  border: 2px solid var(--border-color-gray);
  border-radius: var(--border-l);
  color: var(--tooltip-text-color);
}

.bankLogo {
  /* width: 150px; */
  width: 100%;
  margin-bottom: var(--indent-base);
}

.example {
  font-size: 14px;
  font-weight: 300;
}
