.wrapperHomeCategoriesRow {
  padding-bottom: var(--indent-base);
  min-height: 44px;
}

.marginB  {
  margin-bottom: var(--indent-base);
}

.wrapperHomeCategoriesRow .marginR {
  padding-right: var(--indent-base);
}