.wheelSvg {
  width: 25px;
  height: 25px;
}

.marginButton {
  margin-bottom: calc(var(--indent-base) + 5px);
}

.expandWrapper {
  position: relative;
  margin-left: var(--indent-base);
  transition: 0s;
}

.expandButton {
  margin-bottom: 0;
  border-radius: 0;
  border-left: 2px solid var(--border-color-gray);
}

.expandButton:last-child {
  border-radius: 0 0 var(--border-s) var(--border-s);
}

.iconWrapper {
  width: 25px;
}

.active {
  background-color: var(--bonus-buttons-active-button-background-color);
  margin-bottom: 0;
  transition: 0s;
  /* var(--indent-base);  */
}

.red {
  background-color: var(--bonus-buttons-bonus-count-red-background-color);
  border-radius: 50%;
  border: 1px solid var(--border-color-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.giftSvg path {
  fill: var(--bonus-buttons-svg-path-fill);
}
