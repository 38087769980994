.wrapperPresets {
  display: flex;
  justify-content: space-between;
}

.preset {
  height: 32px;
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-s);
  border: 2px solid var(--border-color-gray);
  background-color: var(--money-operations-wallet-forms-presets-background);
  color: rgb(var(--gray));
}

.active {
  border-color: rgba(var(--third), 0.5);
  color: rgb(var(--third));
}
