.gameFrameWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(
    --desktop-launch-game-pop-up-game-frame-background-color
  );
  z-index: calc(var(--layout-lvl-1) + 4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gameFrame {
  height: 100%;
  /* 260 desktop game with size + margin left + padding inside recommendend */
  width: calc(100% - calc(260px + calc(var(--indent-base) * 2)));
  flex-shrink: 0;
  position: relative;
  transition: 0.5s;
}

.fullSize {
  width: 100%;
}

.gameFrame iframe {
  width: 100%;
  height: 100%;
  border-radius: var(--border-xl);
}

.wrapperDesktopGames {
  box-shadow: rgba(var(--third), 0.24) 0px 3px 8px;
  border-radius: var(--border-l);
  padding: var(--indent-base);
  background-color: var(
    --desktop-launch-game-pop-up-games-wrapper-background-color
  );

  position: relative;

  height: 90%;
  width: 95%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.additionalInfo {
  /* width: calc(20% - var(--indent-base)) ; */
  border-radius: var(--border-xl);
  background-color: var(--desktop-launch-game-pop-up-additional-info-background-color);
  height: 100%;
  padding: var(--indent-base) calc(var(--indent-base) / 2);

  overflow-y: auto;
  position: relative;
}

.svg {
  position: absolute;

  top: -20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;

  padding: var(--indent-base);
  background-color: var(--desktop-launch-game-pop-up-svg-background-color);
  border: 2px solid var(--border-color-gray);
}

.svg path {
  fill: var(--desktop-launch-game-pop-up-svg-path-fill);
}

.closeSvg {
  right: -20px;
}

.openProviderGamesBtn {
  position: sticky;
  bottom: 0;
}

.arrowToggle {
  top: 0;
  left: calc(var(--indent-base) * -3);
  transform: rotate(-90deg);
}

.toggleStateArrowPosition {
  left: calc(var(--indent-base) * -1);
}

.hideGames {
  overflow: hidden;
  padding: 0;
  animation: HideRecommendedGames 0.5s forwards;
}

@keyframes HideRecommendedGames {
  0% {
    width: calc(260px + calc(var(--indent-base) * 2));
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

.depositBtn {
  margin-bottom: var(--indent-base);
}
