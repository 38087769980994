.title {
  margin-bottom: var(--indent-base);
  font-size: var(--font-size-l);
  font-weight: 500;
  text-align: center;
  color: rgb(var(--white));
}

.subTitle {
  margin-bottom: var(--indent-base-double);
  font-size: var(--font-size-m);
  font-weight: 400;
  text-align: center;
  color:var(--referral-sub-title-color);
}

