.wrapperPromotionBanner {
  height: 48px;
  background: rgb(0, 0, 0);
  margin-bottom: var(--indent-base);
  border-radius: var(--border-l);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 var(--indent-base);

  .title {
    color: rgb(var(--primary));
    text-shadow: 1px 1px 0px #4d21ff;
    font-size: 0.9rem;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    position: relative;
    z-index: var(--layout-lvl-1);
  }

  .gift {
    position: absolute;
    right: 0;
    animation: Scaling 3s infinite;
  }
}

@keyframes Scaling {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}
