.wrapperCasinoTournament {
  width:100%;
  transition: 0.5s;
  overflow: auto;
  padding-bottom: var(--indent-base);
}

.wrapperTournamentCard {
  margin-bottom: calc(var(--indent-base) * 2);
}

.wrapperPlayer {
  margin-bottom: var(--indent-base);
}

.dash {
  border-bottom: 2px solid var(--border-color-gray);
  border-radius: var(--border-xs);
  margin-bottom: var(--indent-base);
}

.skeletWrapper {
  height: calc(var(--app-height) - 300px);
  overflow: hidden;
  width: 100%;
}