.wrapperCasinoDrawer {
  padding: var(--indent-base);
  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 1;
}

.marginBottom {
  margin-bottom: calc(var(--indent-base) + 5px);
}

.fillAsButtonText path {
  fill: var(--button-color-primary-text);
}

.marginBottom svg {
  width: 20px;
  height: 20px;
}

.dash {
  height: 2px;
  background: var(--casino-drawer-dash-background-color);
  border-radius: var(--border-xs);
}

.bigMargin {
  margin-bottom: calc(var(--indent-base) * 2);
}

.wrapperLangAndSupport {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonLang {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 36px;

  border-radius: 50%;
  border: var(--casino-drawer-button-language-border);

  background-color: var(--casino-drawer-button-language-background-color);
}

.desktopLogo {
  display: flex;
  justify-content: center;
  margin-bottom: var(--indent-base);
}

.desktopLogo svg {
  height: calc(var(--height-48) * 2);
  width: 100%;
  max-width: 200px;
}

.green path {
  fill: var(--casino-drawer-icon-path-fill);
}

.stroke path {
  stroke: var(--casino-drawer-icon-path-fill);
}

.hasFavItems path {
  fill: var(--casino-drawer-icon-path-fill);
  margin-left: auto;
}

.vipClubSvg path {
  fill: var(--casino-drawer-vip-club-path-fill);
}

.favItems {
  color: var(--casino-drawer-fav-items-color);
  font-size: 12px;
}

.email {
  color: var(--casino-drawer-email-color);
  font-size: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  background-color: var(--casino-drawer-email-background-color);
  padding: var(--indent-base);
  border-radius: var(--border-l);
  border: 2px solid var(--border-color-gray);
  margin-left: var(--indent-base);
}

.left {
  /* margin-left: var(--indent-base); */
}

.rowSupport {
  display: flex;
  align-items: center;
}

.jivoBtn {
  background-color: var(--casino-drawer-jivo-button-background-color);
  border: 2px solid var(--border-color-gray);
  padding: calc(var(--indent-base) / 2);
  border-radius: var(--border-l);
}

.contentDrawerWrapper {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .wrapperCasinoDrawer {
    width: var(--drawer-full-width);
    flex-shrink: 0;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: var(--app-height);
    position: sticky;
    top: 0;
    padding: 0;
  }

  .contentDrawerWrapper {
    padding: var(--indent-base);
  }

  .desktopBottomWrapper {
    padding: var(--indent-base);
  }

  .bigMargin {
    margin-bottom: var(--indent-base);
  }

  .short {
    width: 105px;
  }

  .short .wrapperLangAndSupport {
    display: flex;
    flex-direction: column;
  }

  .short .buttonLang {
    margin-bottom: var(--indent-base);
  }

  .short .email {
    width: 100%;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
    margin-left: initial;
    font-size: 0.7rem;
  }

  .rowSupport.column {
    width: 100%;
    flex-direction: column;
    gap: var(--indent-base);
  }
}

.downloadIcon {
  --size: 20px;
  width: var(--size);
  height: var(--size);
}