.wrapperReferralCopyContent {
  min-height: 90dvh;
}

.availableSum {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(var(--primary));
  margin-bottom: var(--indent-base-double);
}

.marginB {
  margin-bottom: var(--indent-base-double);
}

.grayColor {
  color: rgb(var(--gray));
}