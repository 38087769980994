.ticketWrapper {
  margin-bottom: var(--indent-base);
  background-color: rgb(var(--dark));
  border-radius: var(--border-s);
  overflow: hidden;
  border: 2px solid var(--border-color-gray);
  color: rgb(var(--gray));
  min-height: 150px;
  transition: 0.5s;
}

.isHiddenContend {
  min-height: var(--height-48);
}

.disabledPaddingBtn {
  padding-bottom: 0;
}


.horizontalPadding {
  padding: var(--indent-base)
}

.rightColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.marginB {
  margin-bottom: var(--indent-base);
}

.ordinarInfo {
  display: flex;
  justify-content: space-between;


  padding: var(--indent-base) calc(var(--indent-base) / 2);
  border-bottom: 2px solid var(--border-color-gray);

  font-size: 12px;
  background: rgb(var(--semi-dark));

  height: var(--ticket-ordinar-height);
}

.acceptedOdd {
  width: 55px;
  font-size: 14px;
  text-align: end;
  font-weight: 700;
  color: rgb(var(--white));
  margin-bottom: var(--indent-base);
}