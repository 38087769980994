.row {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.betSlipOdd {
  color:rgb(var(--white));
  margin-left: var(--indent-base);
}

.grayText {
  color:rgb(var(--gray));
}

.wrapperOdd {
  border:none;
  pointer-events: none;
  background-color: transparent;
  font-size: 16px;
  padding: 0;
  height: initial;
}