.partucularCashOutWrapper {
  margin: calc(var(--indent-base) / 2);
  border-radius: var(--border-l);
  border-top: 2px solid var(--border-color-gray);
  background: rgb(var(--semi-dark));
  padding: var(--indent-base);
}

.particularCashOutTitle {
  font-size: 16px;
  text-align: center;
  color: rgb(var(--white));
  margin-bottom: var(--indent-base);
}

/* Slider for particular cashout */
.progress {

  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;

  height: 10px;
  border: 2px solid var(--border-color-gray);
  margin-bottom: calc(var(--indent-base) * 2);
}

/* Thumb: webkit */
.progress::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 18px;
  width: 18px;
  background-color: rgb(var(--third));
  border-radius: 50%;
  border: none;

  transition: .2s ease-in-out;
}

/* Thumb: Firefox */
.progress::-moz-range-thumb {
  height: 18px;
  width: 18px;
  background-color: rgb(var(--third));
  border-radius: 50%;
  border: none;
  transition: .2s ease-in-out;
}

/* Hover, active & focus Thumb: Webkit */
.progress::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(var(--third), 0.1)
}

.progress:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(var(--third), 0.2)
}

.progress:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(var(--third), 0.2)
}

/* Hover, active & focus Thumb: Firfox */
.progress::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(var(--third), 0.1)
}

.progress:active::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(var(--third), 0.2)
}

.progress:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(var(--third), 0.2)
}

/* PRESETS */
.presets {
  display: flex;
  justify-content: space-between;
}


.dynamicPresets {
  display: flex;
  width: calc(100% - var(--small));
}

.preset {
  margin-left: var(--indent-base);
}

.preset:first-child {
  margin-left: 0;
}