.wrapperGridCasinoGame {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 8px;
  margin-bottom: calc(var(--indent-base) * 2);
}

.verticalGrid {
  grid-template-columns: repeat(3, 1fr);
}

.buttonTop {
  position: fixed;
  right: var(--indent-base);
  bottom: 80px;
  width: 45px;
  height: 45px;
  background-color: var(--casino-grid-games-button-top-background-color);
  z-index: 3;
  border-radius: 50%;
  border: 2px solid rgb(var(--third));
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconTop {
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
}

.iconTop path {
  fill: var(--casino-grid-games-icon-top-path-fill);
}

@media (min-width: 768px) {
  .wrapperGridCasinoGame {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: var(--indent-base);
  }
}
