.wrapperDepositQrForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--indent-base) 0;
}

.depositQrTitle {
  text-align: center;
  color: var(--qr-form-deposit-title-color);
  font-size: 16px;
  margin-bottom: calc(var(--indent-base) * 2);
}

.qrWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: calc(var(--indent-base) * 3);
}

.inputInfo {
  margin-bottom: calc(var(--indent-base) * 2);
}

.minInfo {
  display: flex;
  align-items: center;
}

.minText {
  margin-left: var(--indent-base);
  color: var(--qr-form-info-min-text-color);
}

.minText span {
  color: var(--qr-form-info-min-text-span-color);
}
