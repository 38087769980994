.wrapperAvailableForWithdraw {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--available-for-withdraw-color);
  font-weight: 300;
  /* because amount input has label with show dynamic min-max */
  margin-bottom: calc(var(--indent-base) * 2);
}

.availableTitle {
  font-size: 13px;
  margin-bottom: 5px;
  color: var(--available-for-withdraw-title-color);
}

.availableBalance {
  font-size: 18px;
}
