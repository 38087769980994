.wrapperNavigationBar {
  position: fixed;
  bottom: 0;
  z-index: calc(var(--layout-lvl-1) + 4);
  left: 0;
  width: 100%;
  height: var(--navigation-bar-height);
  border-top: 2px solid var(--border-color-gray);
  background: var(--navigation-bar-background-color);
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.hide {
  bottom: calc((var(--height-48) + var(--indent-base)) * -1);
}

.iconButtonContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.greenPath path {
  fill: var(--navigation-bar-green-path-fill);
}

.navigation {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.navigationButton {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--navigation-bar-button-color);
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
}

.navigationButton svg {
  height: 24px;
}

.text {
  font-size: 10px;
}

.icon {
  margin-bottom: 6px;
}

.activeNav {
  transition: 0.5s;
  border-radius: var(--border-s);
  border: 2px solid var(--border-color-gray);
  background-color: var(--navigation-bar-active-nav-background-color);
  /* //rgb(var(--color-dark)); */
}

.navigationButton svg path,
.navigationButton svg rect {
  fill: var(--navigation-bar-svg-path-fill);
}

.activeNav svg,
.activeNav svg path {
  fill: var(--navigation-bar-active-nav-svg-path-fill);
}
