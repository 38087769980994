.row {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.counter {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:rgb(var(--semi-dark));
  background: rgb(var(--primary));
  font-size:12px;
  border-radius: 50%;
}

.name { 
  margin-left: var(--indent-base);
  font-size: 14px;
  color:rgb(var(--white))
}
