.wrapperMethods {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  /* top padding tabs + pagging */
  height: calc(100% - 65px);
}

.kycBlock {
  min-height: 100px;
}

.maxHeight {
  height: 100%;
}

.methods {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  padding-top: var(--indent-base);
  background: var(--money-operations-wallet-forms-content-background-color);
}

.title {
  color: var(--withdraw-methods-title-color);
  margin-bottom: var(--indent-base);
  text-align: center;
}

.kycTitle {
  margin-bottom: var(--indent-base);
  text-align: center;
  text-transform: uppercase;
}

.subTitle {
  text-align: center;
}
