.wrapperCredentials {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: var(--full);
}

.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--full);
  margin-bottom: var(--indent-base-double);
}

.username {
  color: var(--credentials-info-username-color);
  font-size: var(--font-size-xs);
}

.result {
  color: var(--credentials-info-result-color);
  font-size: var(--font-size-m);
  width: calc(var(--large) - (var(--small) + var(--indent-base)));
  overflow: hidden;
  text-overflow: ellipsis;
}
