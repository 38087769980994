.wrapperHeaderSport {
  font-size: 12px;
  color: var(--back-header-sport-color);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background: var(--back-header-sport-background-color);
  padding: var(--indent-base);
  height: var(--height-48);
}

.arrowBack {
  transform: rotate(-90deg);
}

.sportName {
  margin-left: var(--indent-base);
}
