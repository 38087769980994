.wrapperPixInput {
   display: flex;
   align-items: center;
}


.marginB {
  margin-bottom: var(--indent-base);
}

.tooltipWrapper {
  border:2px solid var(--border-color-gray);
  padding:5px;
  border-radius: var(--border-s);
  font-size: 12px;
  background-color: rgb(var(--semi-dark));
  color: rgb(var(--gray));
  margin-bottom: var(--indent-base);
}