.wrapperDataNotFound {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--not-found-data-color);
}

.column {
  flex-direction: column;
}
