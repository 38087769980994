.marginB {
  margin-bottom: var(--indent-base);
}


.wrapperCashoutButtons {
  display: flex;
  align-items: center;
}

.wrapperCashoutButtons .cashoutBtn {
  width: 100%;
  flex-shrink: initial;
  min-width: initial;
  max-width: initial;
}

.settingSvg {
  margin-left: var(--indent-base);
  width: var(--height-48);
  height: var(--height-48);
  padding: var(--indent-base);
  border: 2px solid var(--border-color-gray);
  border-radius: var(--border-l);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}