.bdtInfo {
  font-size: 14px;
  color: var(--nagad-deposit-bdt-info-color);
}

.billingAccountInput {
  display: flex;
  align-items: center;
}

.copyIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
