.wrapperGameHeader {
  margin-bottom: var(--indent-base);
  padding-top: var(--indent-base);
}

.recommendedWrapper {
  margin: 0 0 20px var(--indent-base);
  width: calc(100% - var(--indent-base));
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}