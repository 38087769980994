.wrapperBankListPopUp {
  height: var(--full);
  position: fixed;
  z-index: calc(var(--layout-lvl-3) + 1);
  width: var(--full);
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  background-color: rgba(var(--semi-dark), 0.7);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.limits {
  font-size: var(--font-size-s);
  font-weight: 700;
}

.content {
  border-top: 2px solid var(--border-color-gray);
  background-color: rgb(var(--semi-dark));
  border-radius: var(--border-xl);
  padding: var(--indent-base) var(--indent-base) 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 75%;
  position: relative;
  z-index: var(--layout-lvl-2);
}

.list {
  height: calc(var(--full) - var(--height-48));
  width: var(--full);
  overflow-y: auto;
}

.item {
  width: var(--full);
  display: flex;
  justify-content: space-between;
  padding: var(--indent-base-half);
}

.name {
  color: rgb(var(--white));
  font-size: var(--font-size-m);
  display: flex;
  align-items: center;
}

.activeName {
  color: rgb(var(--primary));
}

.img {
  --height: 30px;
  --width: 100px;
  height: var(--height);
  width: var(--width);
}
