.wrapperCasinoHome {
  margin-left: var(--indent-base);
}

.width {
  width: calc(100% - var(--indent-base));
}

.wrapperSlider {
  margin-bottom: var(--indent-base-double);
}

.wrapperCategories {
  margin-bottom: var(--indent-base);
}

.gridGamesWrapper {
  width: calc(100% - var(--indent-base));
}

.wrapperSearchInput {
  width: calc(100% - var(--indent-base));
  margin-bottom: var(--indent-base-double);
}

.desktopBanner {
  width: 100%;
  height: 430px;
  border-radius: var(--border-l);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: var(--indent-base-double);
}

.marginB {
  margin-bottom: var(--indent-base);
}

@media (min-width: 768px) {
  .wrapperCasinoHome {
    width: 100%;
    margin-left: initial;
  }

  .width {
    width: initial;
  }
}
