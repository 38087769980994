.openedColor * {
  fill: var(--arrow-button-opened-svg-path-fill);
  cursor: pointer;
}

.openedColor {
  transition: 0.5s;
  transform: rotate(180deg);
}

.default {
  padding: 5px;
  width: 22px;
  height: 18px;
}

.default path {
  fill: var(--arrow-button-svg-path-fill);
}
