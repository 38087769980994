.wrapperPromoCodeModal {
  background-color: var(--promocode-modal-background);
}

.description {
  font-size: var(--font-size-xl);
  font-weight: 700;
  color: var(--promo-code-modal-text);
  margin-bottom: var(--indent-base);
}

.img {
  width: 100%;
  height: auto;
  margin-bottom: var(--indent-base-double);
}

.header {
  background: initial;
  border: none;
  margin: initial;
}

.nextButton {
  font-weight: 500;
}
