.wrapperBalanceInfo {
  display: flex;
  flex-direction: column;

  background-color: var(--balance-list-balance-info-background-color);
  height: max-content;
  border-radius: var(--border-l);

  overflow: hidden;

  padding: var(--indent-base) var(--indent-base-half);
}

.balanceWrapper {
  padding: var(--indent-base-half) 0;
}

.title {
  font-size: var(--font-size-m);
  color: var(--balance-list-balance-title-color);
  margin-bottom: var(--indent-base-half);
  white-space: nowrap;
}

.rowInfo {
  display: flex;
  align-items: center;
}

.value {
  margin-left: 5px;
  color: var(--balance-list-balance-value-color);
}
