* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-family: "Montserrat";
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

html, body, #root {
  width: 100%;
  min-height: var(--app-height);
  background: rgb(var(--semi-dark))
}

b {
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

#notification-box {
  position: fixed;
  right: -250px;
  top: 60px;
  height: 100%;
  width: 250px;
  z-index: var(--layout-lvl-4);
}

/* Move to global  */
.full {
  height: 100vh;
  width: 100%;
}

.full-w {
  width: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-b {
  margin-bottom: var(--indent-base);
}

@media (min-width: 1140px) {
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: var(--scrollbar-track-background-color);
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(var(--primary));
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(var(--primary));
  }
}