.providerLetter {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
  color: var(--provider-block-letter-color);
}

.wrapperStyles {
  min-height: var(--height-48);
}
