.wrapperAmountInput {
  position: relative;
}

.currencyNameColor {
  color: rgb(var(--gray))
}

.commissionInfoWrapper {
  padding: calc(var(--indent-base) / 2);
  border: 2px solid var(--border-color-gray);
  border-radius: var(--border-s);
  color: var(--money-commission-text-color);
  font-size: var(--font-size-m);
  font-weight: 300;
  z-index: 2;
  background-color: rgb(var(--semi-dark));
  top: 50px
}

.infoCommissionWrapper {
  margin-left: var(--indent-base);
}

.infoCommissionWrapper path {
  fill: rgb(var(--primary));
}

.textBlock {
  margin-left: calc(var(--indent-base) / 2);
  flex-shrink: 0;
  width: calc(100% - calc(var(--indent-base) + 25px));
}

.rules {
  margin-bottom: var(--indent-base);
}

.rules span {
  font-size: 11px;
  font-weight: 700;
}

.additionalInfo span {
  font-size: 11px;
  font-weight: 700;
}

.infoIcon {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
}

.additionalInfo {
  margin-bottom: var(--indent-base);
}

.wrapperButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.goGameBtn {
  color: rgb(var(--white));
  font-weight: 500;
  margin-bottom: var(--indent-base);
  height: 35px;
}

.goGameBtn:last-child {
  margin-bottom: 0;
  color: rgba(var(--white), 0.7)
}


.commissionInfoBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--indent-base);
}