.wrapperCasinoCategoryGames {
  margin-left: var(--indent-base);
  position: relative;
}


.wrapperCategories {
  margin-bottom: var(--indent-base);
}

.gridGamesWrapper {
  width: calc(100% - var(--indent-base));
}

.wrapperFilterItems {
  width: calc(100% - var(--indent-base));
  margin-bottom: var(--indent-base);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.widthAndHeight {
  width: calc(50% - var(--indent-base) / 6);
  height: 32px;
}