.titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--indent-base);
  font-size: 14px;
  font-weight: 700;
}

.progressWrapper {
  background: var(--progress-bar-progress-wrapper-background-color);
  height: 6px;
  width: 100%;
  position: relative;
  border-radius: var(--border-xs);
  overflow: hidden;
}

.progressFill {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: var(--progress-bar-progress-fill-background-color);
  overflow: hidden;
}

.wrapperDownItems {
  display: flex;
  justify-content: space-between;
}

.leftTitle {
  color: var(--progress-bar-title-color);
}

.rightTitle {
  color: var(--progress-bar-percents-color);
}
