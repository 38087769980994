.wrapperSkeletLoader {
  height: 100%;
  background: var(--skelet-loader-wrapper-background-color);
}

.loader {
  height: 100%;
  width: 300%;
  background: var(--skelet-loader-background-color);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
