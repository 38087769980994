.row {
  display: flex;
  align-items: center;
}

.icon {
  width: 55px;
  height: 55px;
}

.text {
  font-size: 0.7rem;
  color: var(--inr-default-deposit-text-color);
  margin-left: var(--indent-base);
}
