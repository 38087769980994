.buttonBackground {
  background-color: rgb(var(--soft-gray));
  border-radius: 50%;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--border-color-gray);
}

.favBase {
  width: 18px;
  height: 18px;
}

.buttonBackground .fav path {
  fill: rgb(var(--third));
}
