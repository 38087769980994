.wrapperDrawer {
  height: 50vh;
  background-color: var(--languages-drawer-background-color);
  border: 2px solid var(--border-color-gray);
  transform: translate(10%, -50%);
  width: 80%;
  border-radius: var(--border-xl);
  position: relative;
  padding-top: 20px;
}

.closeBtn {
  --size: 30px;
  --indent: -15px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: var(--indent);
  right: var(--indent);
  background-color: var(--languages-drawer-close-button-background-color);
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  border: 2px solid var(--border-color-gray);

  cursor: pointer;
}

.closeIcon {
  --size: 14px;
  width: var(--size);
  height: var(--size);
}

.button {
  margin-bottom: var(--indent-base);
  justify-content: flex-start;
  padding: var(--indent-base);
}

.languageWrapper {
  padding: var(--indent-base);
  overflow: auto;
  height: 100%;
}

.iconFlag {
  --size: 25px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .wrapperDrawer {
    transform: initial;
    max-width: 500px;
  }
}
