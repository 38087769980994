.name {
  color: var(--provider-item-row-name-color);
  font-size: 12px;
  font-weight: 500;
}

.value {
  color: var(--provider-item-row-value-color);
  font-size: 12px;
}

.row {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 9px;
  background: var(--provider-item-row-background-color);
  margin-bottom: 6px;
  border-radius: 6px;
}
