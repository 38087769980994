.betSlipWrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  width:100%;
  z-index: 5;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: var(--height-48);
}

.wrapperBetSlipContentShown {
  height: 100%;
  /* header + footer   */
  max-height: calc(100% - calc(var(--height-48) + 60px));

  background-color: rgba(var(--semi-dark),0.5);
}