.content {
  width: var(--full);
  height: var(--full);
  width: var(--full);
  height: var(--full);
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: var(--indent-base);
  margin-bottom: var(--indent-base);
}
