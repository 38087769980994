.customJivoButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--jivo-chat-custom-button-color);
  text-decoration: none;
  font-size: 10px;
  font-weight: 400;
  position: relative;
}

.customJivoButtonWrapper > svg {
  width: 24px;
  height: 24px;
}

.loaderSize {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-width: 2px;
}

.supportAgentActive {
  border: 1px solid var(--jivo-chat-support-agent-active-border-color);
}

.newMsg {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--jivo-chat-new-message-background-color);
}

.supportSvg path {
  fill: var(--jivo-chat-support-svg-path-fill);
}
