.wrapperBetSlipButton {
  width: 100%;
  height: var(--height-48);
  padding:11px var(--indent-base);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:rgb(var(--white));
  border-top: 2px solid var(--border-color-gray);
  background: rgb(var(--semi-dark));
  box-shadow: 0px -16px 24px 0px rgba(15, 17, 27, 0.50); 
}

.row {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.betSlipOdd {
  color:rgb(var(--white));
  margin-left: var(--indent-base);
}
