.row {
  display: flex;
  align-items: center;
}

.marginLeft {
  margin-left: 20px;
}

.btnText {
  color: var(--auth-buttons-text-color);
}

@media (min-width: 768px) {
  .wrapperAuthButtons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
