.wrapperLaunchGame {
  /* min-height: calc(var(--app-height) - 190px); */
  min-height: 128vw;
  background-color: var(--launch-game-background-color);
  border-radius: var(--border-24);
  margin-bottom: calc(var(--indent-base) * 2);
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 12px;

  overflow: hidden;
}

.iconMrg {
  margin-bottom: var(--indent-base);
}

.rowCenter {
  min-width: var(--medium);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  background-color: var(--launch-game-row-center-background-color);
  padding: var(--indent-base);
  border-radius: var(--border-xl);
  border: 2px solid var(--border-color-gray);
}

.playText {
  color: var(--launch-game-play-text-color);
  margin-bottom: var(--indent-base);
}

.gameImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--launch-game-game-image-background-color);
  object-fit: cover;
}

@media (min-width: 1170px) {
  .wrapperLaunchGame {
    /* 96header = margin 12 / 60- footer /  38px headerGame  / + 12px margin-btn */
    min-height: calc(var(--app-height) - 238px);
  }

  .gameImg {
    filter: blur(10px);
  }
}
