.wrapperBettingDrawer {
  padding: var(--indent-base);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

.disabledScroll {
  overflow: hidden;
}

.marginBottom {
  margin-bottom: calc(var(--indent-base) + 5px);
}

.tabs {
  /* padding-top: var(--indent-base);
  position: sticky;
  top: calc(var(--indent-base) * -1); */
  /* background-color: var(--betting-drawer-tabs-background-color); */
}
