.categoryHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--indent-base);
  padding-right: var(--indent-base);
  color: var(--casino-home-row-header-category-name-color);
}

.leftInfo {
  display: flex;
  align-items: center;

  .categoryIcon {
    --size: 16px;
    width: var(--size);
    height: var(--size);

    path {
      fill: var(--casino-home-row-header-category-icon-path-fill);
    }

    @media screen and (min-width: 768px) {
      --size: 24px;
    }
  }
}

.categoryName {
  margin-left: 5px;
  font-size: var(--font-size-l);
}

.showAllBtn {
  color: var(--casino-home-row-header-category-show-all-button-color);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  text-transform: capitalize;
}

.arrowMore {
  margin-left: calc(var(--indent-base) / 2);
  transform: rotate(90deg);
}

.arrowMore path {
  fill: var(--casino-home-row-header-category-arrow-more-path-fill);
}

.inHouse svg > g {
  transform: scale(1.6) translate(-9px, -9px);
}
