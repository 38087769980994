.wrapperOrdinarInfo {
  display: flex;
  padding: var(--indent-base);
  font-size: 12px;
  width: 100%;
  position: relative;
  margin-bottom: var(--indent-base);
}

.infoBox {
  /* 115 right side + margin */
  width: calc(100% - calc(115px + var(--indent-base)));
}

.row {
  display: flex;
}

.jsb {
  justify-content: space-between;
}

.wrapperOrdinarInfo .oddValue {
  color: var(--ordinar-info-odd-value-color);
  font-size: 14px;
  border: none;
  font-weight: bold;
}

.errorStatus {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: var(--ordinar-error-status-background-color);
  width: 100%;
  height: 100%;
}

.acceptedStatus {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: var(--ordinar-accepted-status-background-color);
  width: 100%;
  height: 100%;
}


.eventOrLineNotFoundWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  z-index: calc(var(--layout-lvl-1) + 1);
  color: rgb(var(--primary));
}