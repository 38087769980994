.wrapperInGameTickets {
  border-top: 2px solid var(--border-color-gray);
  background-color: var(--ingame-tickets-background-color);
  border-radius: var(--border-xl);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
   margin-bottom: var(--indent-base);
}

.wrapperInGameTickets .tabs {
  margin-bottom: var(--indent-base);
  width: calc(100% - var(--indent-base-double));
}

.content {
  height: calc(var(--app-height) - 200px);
  width: 100%;
  overflow-y: auto;
  margin-bottom: var(--indent-base);
  padding:var(--indent-base)
}
