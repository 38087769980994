.wrapperBonusLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: var(--bonus-cancel-loader-background-color);
  z-index: 2;
}

.size {
  margin: 0 auto;
}
