.calendarLib abbr {
  text-decoration: none;
  text-transform: none;
  font-size: 12px;
  font-weight: 700;
}

.calendarLib .react-calendar__month-view__weekdays__weekday--weekend {
  color: rgb(var(--red));
}

.calendarLib .react-calendar__navigation {
  margin-bottom: var(--indent-base);
  border-bottom: 2px solid var(--border-color-gray);
}

.calendarLib .react-calendar__navigation .react-calendar__navigation__label {
  color: var(--calendar-modal-navigation-button-color);
  font-size: 14px;
  pointer-events: none;
}

.calendarLib .react-calendar__navigation button {
  color: var(--calendar-modal-navigation-button-color);
  font-size: 18px;
}

.calendarLib .react-calendar__navigation button:hover {
  color: rgb(var(--white));
  font-size: 18px;
  background-color: transparent;
}

.calendarLib .react-calendar__navigation__next2-button {
  display: none;
}

.calendarLib
  .react-calendar__navigation
  .react-calendar__navigation__prev2-button {
  display: none;
}

.calendarLib .react-calendar__month-view__weekdays {
  height: var(--height-48);
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendarLib .react-calendar__month-view__days__day--weekend {
  color: rgba(var(--white), 0.6);
}

.calendarLib .react-calendar__tile abbr {
  border-radius: var(--border-s);
  color: var(--calendar-modal-tile-color);
}

.calendarLib .react-calendar__tile--now {
  border-radius: var(--border-s);
  background: rgba(var(--semi-gray), 0.2);
}

.calendarLib .react-calendar__tile--now abbr {
  color: var(--calendar-modal-tile-now-color);
}

.calendarLib .react-calendar__month-view__days__day {
  height: 45px;
}

.calendarLib .react-calendar__tile--active,
.calendarLib .react-calendar__tile--active:enabled:hover {
  background-color: transparent;
  position: relative;
  color: rgba(var(--white), 0.6);
}

.calendarLib .react-calendar__tile--active abbr {
  position: relative;
  color: var(--calendar-modal-active-color);
  font-size: 12px;
  z-index: 2;
}

.calendarLib .react-calendar__tile--active:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  height: 96%;
  width: 96%;
  border: 2px solid rgba(var(--primary), 0.8);
  color: rgb(var(--white));
  border-radius: var(--border-s);
  z-index: 1;
}
