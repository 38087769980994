.profileButton {
  border: 2px solid var(--border-color-gray);
  background-color: var(--profile-info-profile-button-background-color);
  border-radius: var(--border-l);

  width: 36px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 8px;
}

.row {
  display: flex;
  align-items: center;
}

.balanceWrapper {
  border: var(--balance-wrapper-border);
  height: 36px;
  background-color: var(--profile-info-balance-wrapper-background-color);
  border-radius: var(--border-m);
  position: relative;
  z-index: 4;
  border-right: none;
}

.balanceText {
  padding: 0 var(--indent-base);
  color: var(--profile-info-balance-text-color);
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .arrow {
    transform: rotate(180deg);
    /* NOTICE: because of rotate */
    margin-right: var(--indent-base-half);

    path {
      fill: var(--profile-info-balance-arrow,rgba(255, 255, 255, 0.6))
    }
  }
}

.walletBtn {
  background-color: var(--profile-info-wallet-button-background-color);
  border: 2px solid var(--profile-info-wallet-button-border-color);
  height: 36px;
  width: 36px;
  border-radius: var(--border-l);
  display: flex;
  justify-content: center;
  align-items: center;
}

.portalBalanceWrapper {
  position: fixed;
  top: 50px;
  height: 100%;
  height: 100%;
  width: 100%;
  background: var(--profile-info-portal-balance-wrapper-background-color);
  z-index: calc(var(--layout-lvl-1) + 3);
  display: flex;
  justify-content: flex-end;
}

.wrapperBalanceList {
  position: absolute;
  top: 45px;
  z-index: calc(var(--layout-lvl-1) + 4);
  right: 0;
  min-width: 100%;
}

@media (min-width: 1170px) {
  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .balanceWrapper {
    max-width: 450px;
    width: initial;
  }

  .depositBtn {
    padding: 0 10px;
    height: 100%;
    width: calc(var(--small) + 80);
  }

  .portalBalanceWrapper {
    top: 100px;
  }

  .wrapperBalanceList {
    position: absolute;
    top: 80px;
  }
}
