.odd {
  width: var(--oddWidth);
  height: var(--oddHeight);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--border-color-gray);
  border-radius: var(--border-s);
  background: var(--odd-background-color);
  font-size: 13px;
  color: var(--odd-color);
  transition: 0.5s;
  position: relative;
}

.selected {
  border-color: var(--odd-selected-border-color);
  background-color: var(--odd-selected-background-color);
}

.disabled {
  opacity: 0.5;
}
