.wrapperFreeSpins {
  border: 2px solid var(--border-color-gray);
  padding: var(--indent-base);
  border-radius: var(--border-l);
  max-width: 100%;
  width: 100%;
  background: var(--freespins-background-color);
}

.marginB {
  margin-bottom: var(--indent-base);
}

.wrapperFreeSpinsContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--indent-base);
}

.disabledMarginB {
  margin-bottom: 0;
}

.wrapperFreeSpinInfo {
  font-size: var(--font-size-s);
  color: var(--freespins-info-color);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50%;
}

.title {
  font-size: var(--font-size-l);
  color: var(--freespins-title-color);
}

.title span {
  font-weight: 600;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--indent-base);
  width: 100%;
}

.row span {
  font-size: var(--font-size-s);
}

.wrapperFreeSpinInfo .row span {
  color: var(--freespins-spins-button-span-color);
}

.button {
  flex-shrink: initial;
  max-width: 50%;
  overflow: hidden;
}

.button path {
  fill: rgb(var(--soft-gray));
}

.wrapperCasinoGame {
  width: 160px;
  flex-shrink: 0;
  margin-inline-start: var(--indent-base);
}

.spinsButton {
  border: 2px solid var(--freespins-spins-button-border-color);
  padding: var(--indent-base-half);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-s);
}

.spinsButton span {
  font-size: var(--font-size-m);
}

.progressWrapper {
  margin-bottom: var(--indent-base);
}

.rowButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playBtn {
  flex-shrink: initial;
}

.cancelBonusBtn {
  flex-shrink: 0;
  margin-inline-start: var(--indent-base);
}

/* COUNTER */

/* TODO ERROR STYLES ORDER */
.countDownClasses {
  width: 50%;
  flex-shrink: initial;
  margin-inline-start: var(--indent-base-half);
  display: flex;
  justify-content: space-between;
  height: 48px;
  background: var(--freespins-count-down-background-color);
}
.rowInfo .countDownClasses {
  padding: 0;
  background-color: transparent;
}

.countDownClasses .timeBlockClasses {
  margin-inline-start: var(--indent-base-half);
}
.countDownClasses .timeClasses ~ div {
  color: var(--freespins-count-down-time-div-color);
}

.timeClasses {
  color: var(--freespins-count-down-time-color);
  font-weight: 600;
  font-size: var(--font-size-m);
}

@media (min-width: 768px) {
  .wrapperFreeSpins {
    --width: 450px;
    width: var(--width);
    max-width: var(--width);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .gameImgContainer {
    width: 210px;
  }

  .wrapperCasinoGame {
    width: initial;
  }

  .desktopWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .desktopWrapper > div {
    margin-bottom: 0;
    width: 100%;
  }
}
