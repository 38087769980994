.socialButtonsWrapper {
  display: flex;
  gap: var(--indent-base);
}

.socialButton {
  --size: 38px;
  width: var(--size);
  height: var(--size);
  background-color: var(--social-buttons-button-background-color);
  border-radius: 50%;
  border: var(--social-buttons-button-border);
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (min-width: 768px) {
    cursor: pointer;
  }
}

.shortView {
  flex-direction: column;
  align-items: center;
  gap: var(--indent-base);
}

.shortView .socialButton {
  margin-left: 0;
}
