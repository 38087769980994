.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: calc(var(--indent-base) * 2);
  color: var(--modal-title-color);
  font-size: 18px;
}

.titleWrapper > svg path {
  fill: var(--modal-title-svg-path-fill);
}

.row {
  display: flex;
  align-items: center;
}

.marginL {
  margin-left: var(--indent-base);
}
