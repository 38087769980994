.leftWrapper {
  max-width: 70%;
  width: 100%;

}

.teamBetName {
  margin-bottom: calc(var(--indent-base) / 2 );
  font-weight: 700;
  font-size: 14px; 
  color:rgb(var(--white));
}

.teamMarketName {
  margin-bottom: calc(var(--indent-base) / 2 );
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.teams {
  font-size: 10px;
}