.ordinarInfo {
  width: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: calc(var(--indent-base) * 2);
}

.nameEvent {
  color: var(--event-info-name-color);
  margin-bottom: 5px;
}

.marketName {
  color: var(--event-info-market-name-color);
}

.teamNames {
  color: var(--event-info-team-names-color);
  font-size: 12px;
}

.closeSvg {
  width: 25px;
  height: 25px;
  padding: calc(var(--indent-base) / 2);

  position: absolute;
  top: 0;
  left: calc(var(--indent-base) / -2);
  z-index: calc(var(--layout-lvl-1) + 2);
}
