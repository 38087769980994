.wrapperBonusOnDeposit {
  border: 2px solid var(--border-color-gray);
  padding: var(--indent-base);
  border-radius: var(--border-l);
  position: relative;
  overflow: hidden;
  width: 100%;
  background: var(--bonus-on-deposit-background-color);
}

.content {
  position: relative;
  z-index: var(--layout-lvl-1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: var(--font-size-xl);
  margin-bottom: var(--indent-base);
  color: var(--bonus-on-deposit-title-color);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.title .left {
  display: flex;
  margin-inline-start: 0;
}

.title .detailsBtn {
  font-size: var(--font-size-s);
  color: var(--bonus-on-deposit-title-details-button-color);
  z-index: var(--layout-lvl-1);
  text-decoration: underline;
}

.title span {
  font-size: var(--font-size-xl);
  font-weight: 700;
  margin-inline-start: var(--indent-base);
}

.bonusOnDepositImg {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  transform: rotate(0deg);
}

.activeIcon {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.shortDescription {
  margin-bottom: var(--indent-base-double);
  width: 100%;
}

.disabledMarginB {
  margin-bottom: 0;
}
.row {
  display: flex;
  justify-content: space-between;
  color: var(--bonus-on-deposit-row-color);
  font-size: var(--font-size-s);
  margin-bottom: var(--indent-base);
}

.row:last-child {
  margin-bottom: 0;
}

.row span {
  font-size: var(--font-size-s);
  font-weight: 600;
}

.rowInfo .row span {
  color: var(--bonus-on-deposit-row-span-color);
}

.rowInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  min-height: 150px;
}

.disableText > div,
.disableText > div > span {
  font-size: 0;
}

.zIndex {
  z-index: calc(var(--layout-lvl-1) + 1);
  position: relative;
}

/* COUNTER */

/* TODO ERROR STYLES ORDER */
.countDownClasses {
  width: 50%;
  flex-shrink: initial;
  margin-inline-start: var(--indent-base-half);
  display: flex;
  justify-content: space-between;
  height: 48px;
  background: var(--bonus-on-deposit-count-down-background-color);
}
.rowInfo .countDownClasses {
  padding: 0;
  background-color: transparent;
}

.countDownClasses .timeBlockClasses {
  margin-inline-start: var(--indent-base-half);
}
.countDownClasses .timeClasses ~ div {
  color: var(--bonus-on-deposit-count-down-time-div-color);
}

.timeClasses {
  color: var(--bonus-on-deposit-count-down-time-color);
  font-weight: 600;
  font-size: var(--font-size-l);
}

.button {
  flex-shrink: initial;
  max-width: 50%;
  overflow: hidden;
}

.button path {
  fill: rgb(var(--soft-gray));
}

@media (min-width: 768px) {
  .wrapperBonusOnDeposit {
    --width: 450px;
    width: var(--width);
    max-width: var(--width);
    flex-shrink: 0;
  }
}
