.wrapperCasinoGame {
  height: 120px;
  background-color: var(--casino-game-background-color);
  border-radius: var(--border-s);
  position: relative;
  overflow: hidden;
}

.providerName {
  position: absolute;
  top: 8px;
  left: 8px;
}

.gameImg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.verticalSize {
  height: 150px;
}


@media (min-width: 429px) {
  .wrapperCasinoGame {
    height: 130px;
  }

  .verticalSize {
    height: 180px;
  }
}


@media (min-width: 768px) {
  .wrapperCasinoGame {
    height: 160px;
  }

  .verticalSize {
    height: 250px;
  }
}
