.wrapperTitle {
  padding: var(--indent-base);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(calc(var(--height-48) / 2) + calc(var(--indent-base) * 2));
  border-top: 2px solid var(--border-color-gray);
  font-size: var(--font-size-s);
}

.visibleHistoryTitle {
  color: var(--cashout-records-visible-history-title-color);
  border-bottom: 2px solid var(--border-color-gray);
}

.rotated {
  transform: rotate(180deg);
}

.cashoutHistoryItemWrapper {
  padding: var(--indent-base) var(--indent-base);
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: calc(var(--indent-base) / 2);
}

.cashoutHistoryItemWrapper:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.title {
  font-size: 12px;
  margin-bottom: calc(var(--indent-base) / 2);
  color: var(--cashout-records-cashout-history-item-title-color);
}

.value {
  font-size: 14px;
  color: var(--cashout-records-cashout-history-item-value-color);
  font-weight: 700;
}

.timeBlock {
  font-size: 10px;
  color: var(--cashout-records-cashout-history-item-time-block-color);
}
