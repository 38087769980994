.listWrapper {
  height: calc(100% - 25px);
  overflow-y: auto;

  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  gap: var(--indent-base);
  margin-bottom: var(--indent-base);
}

.listWrapper:last-child {
  margin-bottom: 0;
}

.itemWrapper {
  background-color: var(--list-item-wrapper-background-color);
  border-radius: var(--border-s);
  border: 2px solid var(--border-color-gray);
  color: var(--list-item-wrapper-color);
  text-align: center;
}

.name {
  padding: calc(var(--indent-base) / 3);
  background-color: var(--list-fiat-channel-name-background-color);
  border-top: 2px solid var(--border-color-gray);
  font-size: 10px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.subTitle {
  font-size: 14px;
  color: var(--list-crypto-subtitle-color);
  padding: calc(var(--indent-base) / 2) var(--indent-base);
  position: sticky;
  top: calc(var(--indent-base) * -2);
  background-color: var(--money-operations-wallet-forms-content-background-color);
}

.loaderItem {
  width: 100%;
  margin-bottom: var(--indent-base);
  border-radius: var(--border-s);
  overflow: hidden;
}

.defaultImg {
  width: 45px;
  height: 45px;
  margin: calc(var(--indent-base) / 2);
  /* background-color: var(--list-fiat-default-image-background-color); */
  padding: calc(var(--indent-base) / 4);
  border-radius: var(--border-s);
}

.defaultImg path {
  fill: var(--list-fiat-default-image-path-fill);
}
