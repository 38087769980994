.wrapperStakeInfo {
  padding: calc(var(--indent-base) / 2);

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: rgb(var(--semi-gray));

  height: var(--ticket-stake-info);
}


.title {
  font-size: var(--font-size-xs);
  margin-bottom: calc(var(--indent-base) / 2);
}

.value {
  color: rgb(var(--white));
  font-size: var(--font-size-m);
  font-weight: 700;
}