.providerItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--provider-box-background);
  border-radius: 6px;
  overflow: hidden;
  height: 55px;
  width: 120px;
  text-align: center;
  padding: 0 calc(var(--indent-base) / 2);
}

.icon {
  flex-shrink: 0;
  color: rgb(var(--semi-gray));
  width: calc(100% - var(--indent-base));
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  height: 40px;
}
