.wrapperBonusButton {
  width: 36px;
  height: 36px;
  margin-left: var(--indent-base);
  background: var(--gradient-green);
  border-radius: var(--border-l);
  padding: calc(var(--indent-base) / 3);
  position: relative;
}

.wrapperBonusButton > img {
  width: 100%;
  height: 100%;
}

.count {
  position: absolute;
  background: rgb(var(--red));
  top: -3px;
  right: -3px;
  padding: 2px;
  font-size: 10px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}