.wrapperDesktopProfileView {
  display: flex;
  align-items: center;
  /* border:2px solid var(--border-color-gray); */
  border-radius: var(--border-xl);
  padding: 5px var(--indent-base);
}

.profileIcon {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--desktop-profile-view-profile-icon-background-color);
  border-radius: 50%;
  border: 2px solid var(--border-color-gray);
}

.profileContent {
  margin-left: var(--indent-base);
  font-size: 12px;
  color: var(--desktop-profile-view-profile-content-color);
  display: flex;
}

.username {
  color: var(--desktop-profile-view-profile-content-username-color);
  margin-bottom: 5px;
}

.loyaltyLvl {
  font-size: 12px;
}

.threeDots {
  margin-left: 20px;
  position: relative;
  top: -5px;
  cursor: pointer;
}
