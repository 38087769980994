.wrapperBonusInfo {
  color: var(--promo-code-modal-text);
  margin-bottom: var(--indent-base-double);
  width: 100%;
}

.title {
  margin-bottom: var(--indent-base);
  text-align: left;
  font-weight: 700;
}

.description {
  font-size: var(--font-size-s);
}
