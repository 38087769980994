
.actionBox {
  width: 115px;
  margin-left: var(--indent-base);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


.trashBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  margin-bottom: calc(var(--indent-base) / 2);
  width: 100%;
}
