.wrapperPlayButton  { 
  background-color: rgb(var(--icon-play-background));
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  cursor: pointer;
  box-shadow: 0px 0px 9px rgba(var(--icon-play-background), 0.25), 0px 0px 9px rgba(var(--icon-play-background), 0.25);
  transition: 0.5s;
}