.categoryHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--indent-base);
  padding-right: var(--indent-base);
}

.leftInfo {
  display: flex;
  align-items: center;
}

.categoryIcon {
  --size: 16px;
  width: var(--size);
  height: var(--size);

  @media screen and (min-width: 768px) {
    --size: 24px;
  }
}

.categoryIcon path {
  fill: var(--provider-row-header-category-icon-path-fill);
}

.categoryName {
  margin-left: 5px;
  font-size: 16px;
  /* color: var(--provider-row-header-category-name-color); */
  color: var(--casino-home-row-header-category-name-color);
  
}

.showAllBtn {
  color: var(--provider-row-header-category-show-all-button-color);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  text-transform: capitalize;
}

.arrowMore {
  margin-left: 5px;
  transform: rotate(90deg);
}

.arrowMore path {
  fill: var(--provider-row-header-category-arrow-more-path-fill);
}
