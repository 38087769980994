

.ruleContent {
  max-height: 70vh;
  overflow-y: auto;
  padding: 0 var(--indent-base);
}

.marginBottom {
  margin-bottom: var(--indent-base);
}

.content {
  background-color: var(--casino-tournament-rules-content-background-color);
  padding: var(--indent-base);
  border-left: 2px solid var(--border-color-gray);
  border-bottom: 2px solid var(--border-color-gray);
  border-right: 2px solid var(--border-color-gray);
}

.ruleBlock {
  display: flex;
  margin-bottom: var(--indent-base);
}

.point {
  width: 20px;
  font-size: 14px;
  color: var(--casino-tournament-rules-block-point-color);
  flex-shrink: 0;
}

.ruleText {
  font-size: 14px;
  color: var(--casino-tournament-rules-block-rule-text-color);
}

@media (min-width: 768px) {
  .wrapperTournamentRules {
    height: 550px;
    width: 450px;
  }

  .ruleContent {
    height: 100%;
  }
}
