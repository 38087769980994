.wrapperTabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--indent-base);
}

.wrapperTab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 10px;
}

.activeTab {
  background: var(--bonus-pack-active-tab-background-color);
  border-radius: var(--border-l);
}
