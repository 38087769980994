.wrapperPaymentMessageInfo {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: calc(100% - var(--indent-base));
  border-radius: var(--border-s);
  padding: var(--indent-base);
  background: var(--payment-message-info-background-color);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--payment-message-info-color);
}

.jscSB {
  justify-content: space-between;
}

.titleStatus {
  font-size: 24px;
  color: var(--payment-message-info-title-status-color);
  font-weight: 700;
  margin-bottom: calc(var(--indent-base) * 2);
}

.titleQrCode {
  text-align: center;
  font-weight: 400;
  color: var(--payment-message-info-title-qr-code-color);
  margin-bottom: var(--indent-base);
  font-size: 16px;
  padding: 0 var(--indent-base);
}

.titleQrCode span {
  color: var(--payment-message-info-title-qr-code-span-color);
  font-weight: 600;
}

.descriptions {
  font-size: 14px;
}

.textWrapper {
  margin: calc(var(--indent-base) * 2);
  text-align: center;
  color: var(--payment-message-info-text-wrapper-color);
  padding: calc(var(--indent-base) * 2);
}
